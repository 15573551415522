<template>
    <div>
        <div>
            <el-tabs v-model="submenu" @tab-click="subMenuHandle">
                <div>
                    <announceHeader ref="headSearch" :markcouter="markcouter" @fuzzy-search="fuzzySearch"
                        @clear-query="clearQuery">
                    </announceHeader>
                </div>
                <el-tab-pane label="公示" name="publicity">
                    <div class="double_add">
                        <div class="plus_add"><el-button type="primary" icon="el-icon-plus"
                                @click="addFrom('0')">新增</el-button>
                        </div>
                    </div>
                    <div class="pitable">
                        <el-table :data="standardData" :row-class-name="tabRowClassName" style="width: 100%"
                            :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                            <el-table-column label="ID" prop="id" align="center" width="100">
                            </el-table-column>
                            <el-table-column label="状态" prop="status" align="center" width="130px">
                                <template slot-scope="{ row }">
                                    <div v-if="!row.status">
                                        <el-tag type="info">未校验</el-tag>
                                    </div>
                                    <div v-else-if="row.status == 1"><el-tag type="warning">已校验未审核</el-tag></div>
                                    <div v-else-if="row.status == 2"><el-tag type="success">已审核</el-tag></div>
                                    <div v-if="!row.publish"><el-tag type="info">未发布</el-tag></div>
                                    <div v-else-if="row.publish == 1"><el-tag type="success">已发布</el-tag></div>
                                </template>
                            </el-table-column>
                            <el-table-column label="标题" prop="subject" align="center">
                                <template slot-scope="{ row }">
                                    <span class="subtext" @click="infoEvent(row)">{{ row.subject }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="创建日期" prop="created_at" align="center" width="130px">
                                <template slot-scope="{ row }">
                                    <span>{{
                                        row.created_at | formatDate("yyyy-MM-dd")
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="150px">
                                <template slot-scope="{ row }">
                                    <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                        <el-button icon="el-icon-edit" type="text" @click="handleUpdate(row)"></el-button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                        <el-button icon="el-icon-delete" type="text" @click="handleDelete(row)"></el-button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="审核" placement="top">
                                        <el-button icon="el-icon-setting" type="text" @click="handleCheck(row)"></el-button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="撤回" placement="top">
                                        <el-button icon="el-icon-refresh-right" type="text"
                                            @click="withDraw(row)"></el-button>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页 -->
                    <div class="pageTotal">
                        <div class="block">
                            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
                            </el-pagination>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="公告" name="notice">
                    <div class="double_add">
                        <div class="plus_add"><el-button type="primary" icon="el-icon-plus"
                                @click="addFrom('0')">新增</el-button>
                        </div>
                    </div>
                    <div class="pitable">
                        <el-table :data="standardData" :row-class-name="tabRowClassName" style="width: 100%"
                            :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
                            <el-table-column label="ID" prop="id" align="center" width="100">
                            </el-table-column>
                            <el-table-column label="状态" prop="status" align="center" width="130px">
                                <template slot-scope="{ row }">
                                    <div v-if="!row.status">
                                        <el-tag type="info">未校验</el-tag>
                                    </div>
                                    <div v-else-if="row.status == 1"><el-tag type="warning">已校验未审核</el-tag></div>
                                    <div v-else-if="row.status == 2"><el-tag type="success">已审核</el-tag></div>
                                    <div v-if="!row.publish"><el-tag type="info">未发布</el-tag></div>
                                    <div v-else-if="row.publish == 1"><el-tag type="success">已发布</el-tag></div>
                                </template>
                            </el-table-column>
                            <el-table-column label="标题" prop="subject" align="center">
                                <template slot-scope="{ row }">
                                    <span class="subtext" @click="infoEvent(row)">{{ row.subject }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="创建日期" prop="created_at" align="center" width="130px">
                                <template slot-scope="{ row }">
                                    <span>{{
                                        row.created_at | formatDate("yyyy-MM-dd")
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center" width="150px">
                                <template slot-scope="{ row }">
                                    <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                        <el-button icon="el-icon-edit" type="text" @click="handleUpdate(row)"></el-button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                        <el-button icon="el-icon-delete" type="text" @click="handleDelete(row)"></el-button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="审核" placement="top">
                                        <el-button icon="el-icon-setting" type="text" @click="handleCheck(row)"></el-button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="撤回" placement="top">
                                        <el-button icon="el-icon-refresh-right" type="text"
                                            @click="withDraw(row)"></el-button>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 分页 -->
                    <div class="pageTotal">
                        <div class="block">
                            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
                            </el-pagination>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div>
            <el-dialog :visible.sync="editDialogVisible" :title="public_title" :close-on-click-modal="false"
                @close="cancleEdit()" custom-class="add_dialog">
                <el-form :model="editForm" ref="editForm">
                    <div class="form_line">
                        <el-form-item label="类型" prop="announce_type" v-if="submenu === 'notice' && editForm.id === ''"
                            :rules="[{ required: true, message: '请选择类型', trigger: 'blur' }]">
                            <el-select v-model="editForm.announce_type" placeholder="请选择类型">
                                <el-option v-for="item in filterTypeOptions" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="form_line">
                        <el-form-item label="标题" prop="title"
                            :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]">
                            <el-input placeholder="请输入标题" v-model="editForm.title"></el-input>
                        </el-form-item>
                    </div>
                    <div class="form_line">
                        <el-form-item label="标准名称" prop="project"
                            :rules="[{ required: true, message: '请选择标准名称', trigger: 'change' }]">
                            <el-select v-model="editForm.project" filterable multiple placeholder="请选择标准名称">
                                <el-option v-for="item in standardList" :key="item.id" :label="item.proName"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="form_line">
                        <el-form-item label="网站链接" prop="url" v-if="submenu === 'publicity' || editForm.announce_type === 1"
                            :rules="[{ required: true, message: '请输入url', trigger: 'blur' }]">
                            <el-input placeholder="请输入url" v-model="editForm.url"></el-input>
                        </el-form-item>
                    </div>
                    <div class="form_line">
                        <el-form-item label="上传文件" :rules="[{ required: true, message: '请上传文件', trigger: 'blur' }]">
                            <upload-file ref="uploadimg" :fileType="fileType" @standard-data="getFileData"></upload-file>
                        </el-form-item>
                    </div>
                    <div class="form_line" v-if="submenu === 'notice'">
                        <el-form-item label="是否公开" prop="notice_type"
                            :rules="[{ required: true, message: '请选择是否公开', trigger: 'blur' }]">
                            <el-radio-group v-model="editForm.notice_type">
                                <el-radio label="1">公开</el-radio>
                                <el-radio label="2">非公开</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </div>

                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancleEdit()">取 消</el-button>
                    <el-button type="success" icon="el-icon-position" @click="submitEdit('editForm')">提 交</el-button>
                </div>
            </el-dialog>
        </div>
        <div>
            <el-dialog :visible.sync="detailVisible" custom-class="preview_dialog">
                <div>
                    <qrCode :rowId="rowData == null ? '' : rowData.id" class="big_code"></qrCode>
                </div>
                <div class="img_top"><el-link @click="seeDetail()" :underline="false">点击查看</el-link></div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="detailVisible = false">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <div>
            <el-dialog :visible.sync="checkVisible" custom-class="news_dialog" :close-on-click-modal="false" title="资讯详情">
                <el-steps :space="200" :active="stepActive" finish-status="finish" align-center>
                    <el-step title="创建" icon="el-icon-circle-check"></el-step>
                    <el-step title="校对" icon="el-icon-circle-check"></el-step>
                    <el-step title="审核" icon="el-icon-circle-check"></el-step>
                    <el-step title="发布"></el-step>
                </el-steps>
                <div class="check_content">
                    <div class="time_status">
                        <div class="show_text">
                            <div>
                                <h1>{{ getForm == null ? '' : getForm.subject }}</h1>
                                <h3>{{ getForm == null ? '' : getForm.short_subject }}</h3>
                                <div class="bottom_txt">
                                    <div class="create_date">{{ getForm == null ? '' : getForm.created_at || '-' }}</div>
                                    <div class="right_label">
                                        <el-tag type="danger" v-if="getForm != null && getForm.status == 0">未校验</el-tag>
                                        <el-tag key="未审核" type="warning"
                                            v-else-if="getForm != null && getForm.status == 1">未审核</el-tag>
                                        <el-tag key="未发布" type="info"
                                            v-else-if="getForm != null && getForm.status == 2 && getForm.publish == 0">未发布</el-tag>
                                        <el-tag key="已发布" type="success" v-else>已发布</el-tag>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form_right">
                            <div>
                                <el-radio-group v-model="pass">
                                    <el-radio label="1">通过</el-radio>
                                    <el-radio label="2">不通过</el-radio>
                                </el-radio-group>
                            </div>
                            <div>
                                <div class="text_word">发布意见:</div>
                                <div><el-input type="textarea" v-model="idea"></el-input></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="infoCancle()">取 消</el-button>
                    <el-button type="primary" @click="informateSubmit()">提 交</el-button>
                </div>
            </el-dialog>
        </div>
        <div>
            <infoDetailModal ref="infoDetailRef" :getForm="getForm" :logData="logData"></infoDetailModal>
        </div>
    </div>
</template>  
<script>
import {
    organizeList, publishList, queryInfo, organizationsInfo, publishClose, publishCheck, examineNews, publishNews, cacheOrganize,
    // getCacheData
} from "../../../../../api/enterprise";
import { standardSelect, newPublicityAdd, newPublicityDel, newPublicityEdit, newNoticeAdd, newNoticeEdit, newNoticeDel } from "../../../../../api/project"
import announceHeader from "../components/announceHeader.vue"
import uploadFile from "../components/uploadFile.vue"
import richTextEdit from "../components/richTextEdit.vue"
import qrCode from "../components/qrBarCode.vue"
import infoDetailModal from "../components/InfoDetails.vue"
import standardFile from "../components/standardFile.vue"
// import AddviceForm from "./addviceForm.vue";
export default {
    name: 'publicannounce',
    components: { announceHeader, uploadFile, richTextEdit, qrCode, infoDetailModal, standardFile },
    filters: {
        formatDate: function (timestamp, fmt) {
            if (fmt == undefined || fmt == "" || fmt == null) {
                fmt = "yyyy-MM-dd ";
            }
            if (!timestamp) {
                return "";
            }
            let date = new Date(timestamp);
            // 第一步先替换年份 因为年份跟其他不一样是四位
            // let pat =
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, date.getFullYear());
            }
            // 第二步匹配其他，然后替换
            let obj = {
                "M+": date.getMonth() + 1,
                "d+": date.getDate(),
                "h+": date.getHours(),
                "m+": date.getMinutes(),
                "s+": date.getSeconds(),
            };
            for (let key in obj) {
                let pat = `(${key})`;
                if (new RegExp(pat).test(fmt)) {
                    let str = obj[key] + "";
                    // RegExp.$1  MM dd hh mm ss贪婪匹配
                    fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
                }
            }
            return fmt;
        },
    },
    // inject: [],
    provide() {
        return {

        }
    },
    data() {
        return {
            qrcodeUrl: "../../../../../assets/QRCode.png",
            text: '',
            qrcode: null,
            submenu: "publicity",
            size: 300,
            markcouter: "",
            standardData: [],
            editDialogVisible: false,
            editForm: {
                title: "",
                url: "",
                public: "0",
                project: '',
                //这边是公告新增的
                announce_type: '',
                notice_type: '1',
            },
            file_obj: null, //上传文件
            filterTypeOptions: [
                {
                    label: '公开文件',
                    value: 1,
                },
                {
                    label: '内容留存',
                    value: 2,
                },
            ],
            organizationOptions: [],
            fileType: 'fileWord',
            page: 1,
            limit: 15,
            totalCount: 0,
            totalPage: 1,
            token: "",
            richcontent: "",
            detailVisible: false,
            rowData: null,
            checkVisible: false,
            auditSteps: 0,
            getForm: null,
            pass: "1",
            idea: "",
            logData: [],
            stepActive: 1,
            standardList: [],
            public_title: '新增公示',
            sendType: '20',
            // type_able:false
        }
    },
    props: {

    },
    created() {
        // console.log('打印文件类型', this.fileType)
    },
    mounted() {
        this.token = this.$store.state.token,
            this.getPublishData();
        this.queryStandards();
    },
    methods: {
        queryStandards() {
            // debugger
            standardSelect({ token: this.$store.state.token }).then(rec => {
                // debugger
                console.log('返回的结果', rec)
                this.standardList = rec.data
            })
        },
        //搜索
        fuzzySearch(formdata) {
            this.page = 1
            this.getPublishData(formdata)
        },
        clearQuery() {
            this.getPublishData()
        },
        getPublishData(formdata) {
            // publishList
            // console.log(formdata, '初次请求')
            let data = {
                token: this.$store.state.token,
                page: this.page,
                limit: this.limit,
                type: formdata != undefined && formdata.type != '' ? formdata.type : this.sendType,
                name: formdata === undefined ? undefined : formdata.name,
                tig: formdata != undefined && formdata.tig != '' ? formdata.tig : undefined,
                status: formdata != undefined && formdata.status != '' ? formdata.status : undefined,
                publish: formdata != undefined && formdata.publish != '' ? formdata.publish : undefined,
            }
            publishList(data).then(rec => {
                // debugger
                console.log('公示公告lists', rec)
                this.standardData = rec.data
                this.page = rec.page //当前页
                this.totalCount = rec.totalCount
                this.totalPage = rec.totalPage
                this.limit = rec.limit
            })
        },
        //获取文件的数据
        getFileData(obj) {
            console.log(obj, '打印文件对象')
            this.file_obj = obj.data
        },
        //富文本双向绑定
        contentChange(content) {
            // console.log('接收文本', content)
            this.richcontent = content
        },
        tabRowClassName({ rowIndex }) {
            let index = rowIndex + 1;
            if (index % 2 == 0) {
                return 'warning-row'
            }
        },
        //切换子菜单
        subMenuHandle() {
            this.page = 1
            this.distinguishType()
            this.getPublishData()
            this.$refs.headSearch.qingkong()
        },
        //区分类型
        distinguishType() {
            if (this.submenu == 'publicity') {
                this.sendType = '20'
            } else {
                this.sendType = '11'
            }
        },
        //新增
        addFrom(type) {
            if (type === '0') {
                //新增表单
                this.editForm.title = ''
                this.editForm.id = ''
                if (this.submenu == 'publicity') {
                    this.public_title = '新增公示'
                } else {
                    this.public_title = '新增公告'
                }
                this.editDialogVisible = true
                this.$nextTick(function () {
                    this.$refs.uploadimg.wordList = []
                })
            } else {
                //新建公开意见
                this.$refs.addviceFormRef.adviceDialogVisible = true
            }

        },
        //查询企业
        enterpriseData() {
            let data = {
                page: 1,
                limit: 50,
                token: this.$store.state.token//获取cookie字符串
            }
            organizeList(data).then(rec => {
                // debugger
                this.organizationOptions = rec.data
            })
        },
        handleSizeChange(page) {
            this.limit = page;
            this.distinguishType()
            this.getPublishData();
        },
        handleCurrentChange(page) {
            this.page = page;
            this.distinguishType()
            this.getPublishData();
        },
        //打开详情
        handleUpdate(row) {
            console.log(row, '打印数据')
            this.rowData = row
            this.editDialogVisible = true
            if (this.submenu == 'publicity') {
                //公示
                this.public_title = '修改公示'
                this.editForm.id = row.id
                this.editForm.title = row.subject
                this.editForm.url = row.url
                this.editForm.project = row.publicity
                this.$nextTick(function () {
                    let list = [{ name: row.publicity_file_name, url: row.publicity_file }]
                    this.$refs.uploadimg.wordList = list
                })
            } else {
                //公告
                this.public_title = '修改公告'
                this.editForm.id = row.id
                this.editForm.title = row.subject
                this.editForm.url = row.url
                this.editForm.project = row.notice
                this.editForm.notice_type = String(row.notice_type)
                this.$nextTick(function () {
                    let list = [{ name: row.notice_file_name, url: row.notice_file }]
                    this.$refs.uploadimg.wordList = list
                })
            }
        },
        //标题查看资讯详情
        infoEvent(row) {
            // console.log(this.$refs)
            this.$refs.infoDetailRef.infoDetailVisible = true
            this.infoDetailData(row)
        },
        //查询资讯详情
        infoDetailData(row) {
            let params = {
                id: row.id,
                token: this.token
            }
            queryInfo(params).then(rec => {
                // debugger
                console.log('打印信息', rec)
                this.getForm = rec.data
                this.logData = rec.data.log
            })
        },
        //审核
        handleCheck(row) {
            // console.log(row, '打印行信息')
            this.getForm = row
            this.stepActive = 1 //设置初始值
            if (row.publish == null && row.status == 0) {
                //未校验未发布
                this.stepActive = 1
            } else if (row.status == 1) {
                //有校验未审核
                this.stepActive = 2
            } else if (row.publish == 0 && row.status == 2) {
                //已审核已发布
                this.stepActive = 3
            } else if (row.publish == 1 && row.status == 2) {
                //已审核已发布
                this.stepActive = 4
            }
            this.checkVisible = true
        },
        organizeData(row) {
            organizationsInfo({ token: this.token, id: row.id }).then(rec => {
                // console.log('查询组织详情的结果', rec.data)
                const obj = {
                    name: rec.data.name,
                    id: rec.data.id,
                };
                // console.log('打印要添加的对象内容', obj)
                this.organizationOptions.push(obj)
            })
        },
        //删除
        handleDelete(row) {
            this.$confirm('是否确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (this.submenu == 'publicity') {
                    //公示
                    this.publiciteDelete(row)
                } else {
                    //公告
                    this.noticeDelete(row)
                }
            }).catch(() => {
                // this.$message({
                //   type: 'info',
                //   message: '已取消删除'
                // });
            });
        },
        //提交表单
        submitEdit(formName) {
            console.log(this.editForm, '打印表单数据')
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // console.log(params, '打印提交的表单数据')
                    this.editForm.token = this.token;
                    if (this.editForm.id === undefined || this.editForm.id === '') {
                        if (this.submenu == 'publicity') {
                            //公示
                            this.publiciteAdd()
                        } else {
                            //公告
                            this.noticeAddData()
                        }
                    } else if (this.editForm.id != '') {
                        if (this.submenu == 'publicity') {
                            //公示
                            this.publiciteEdit()
                        } else {
                            //公告
                            this.noticesEdit()
                        }
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //公示的新增
        publiciteAdd() {
            let params = {
                ...this.editForm,
                publicity_file: this.file_obj.url, //文件的url
                publicity_file_name: this.file_obj.name
            }
            //新增
            newPublicityAdd(params).then(rec => {
                // debugger
                console.log('返回的结果', rec)
                if (rec.code == 200) {
                    this.$message({
                        type: "success",
                        message: rec.message
                    })
                    this.getPublishData();
                    this.editDialogVisible = false
                }
            })
        },
        //公告的新增
        noticeAddData() {
            // debugger
            let params = {
                ...this.editForm,
                notice_file: this.file_obj.url, //文件的url
                notice_file_name: this.file_obj.name
            }
            //新增
            newNoticeAdd(params).then(rec => {
                // debugger
                console.log('返回的结果', rec)
                if (rec.code == 200) {
                    this.$message({
                        type: "success",
                        message: rec.message
                    })
                    this.getPublishData();
                    this.editDialogVisible = false
                }
            })
        },
        //公示的修改
        publiciteEdit() {
            let data = {
                ...this.editForm,
                publicity_file: this.file_obj === null ? this.rowData.publicity_file : this.file_obj.url, //文件的url
                publicity_file_name: this.file_obj === null ? this.rowData.publicity_file_name : this.file_obj.name
            }
            newPublicityEdit(data).then(rec => {
                // debugger
                console.log('修改的结果', rec)
                if (rec.code == 200) {
                    this.$message({
                        type: "success",
                        message: rec.message
                    })
                    this.getPublishData();
                    this.editDialogVisible = false
                }
            })
        },
        //公告的修改
        noticesEdit() {
            let data = {
                ...this.editForm,
                notice_file: this.file_obj === null ? this.rowData.notice_file : this.file_obj.url, //文件的url
                notice_file_name: this.file_obj === null ? this.rowData.notice_file_name : this.file_obj.name
            }
            newNoticeEdit(data).then(rec => {
                // debugger
                console.log('修改的结果', rec)
                if (rec.code == 200) {
                    this.$message({
                        type: "success",
                        message: rec.message
                    })
                    this.getPublishData();
                    this.editDialogVisible = false
                }
            })
        },
        //公示的删除
        publiciteDelete(row) {
            newPublicityDel({ token: this.token, id: row.id, source: 4 }).then(rec => {
                // debugger
                console.log('返回的结果', rec)
                if (rec.code == 200) {
                    this.$message({
                        type: "success",
                        message: rec.message
                    })
                    this.getPublishData();
                    this.editDialogVisible = false
                }
            })
        },
        //公告的删除
        noticeDelete(row) {
            newNoticeDel({ token: this.token, id: row.id, source: 4 }).then(rec => {
                // debugger
                console.log('返回的结果', rec)
                if (rec.code == 200) {
                    this.$message({
                        type: "success",
                        message: rec.message
                    })
                    this.getPublishData();
                    this.editDialogVisible = false
                }
            })
        },
        //撤回
        withDraw(row) {
            this.$confirm('是否确认撤回?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    token: this.token,
                    id: row.id
                }
                publishClose(params).then(rec => {
                    // debugger
                    console.log('撤回的结果', rec)
                    if (rec.code == 200) {
                        this.$message({
                            type: "success",
                            message: rec.message
                        })
                        this.getPublishData();
                    }
                })
            }).catch(() => {

            });
        },
        //取消
        cancleEdit() {
            this.editDialogVisible = false
            this.$refs.editForm.resetFields();
        },
        jumpArticle(row) {
            this.detailVisible = true
            this.rowData = row
        },
        seeDetail() {
            window.open("https://gbservice.cn/#/detailbk?id=" + this.rowData.id);
        },
        //资讯取消
        infoCancle() {
            this.checkVisible = false
        },
        //资讯详情提交
        informateSubmit() {
            const { id, status } = this.getForm;
            const submitForm = {
                id: this.getForm.id,
                pass: this.pass,
                idea: this.idea,
                token: this.token,
                source: 4
            };
            if (!status) {
                publishCheck(submitForm).then((res) => {
                    // console.log('打印返回786的值', res)
                    if (res.code == 200) {
                        this.getPublishData();
                        this.idea = ''
                        this.checkVisible = false
                    }
                });
                return;
            }
            if (status == 1) {
                examineNews(submitForm).then((res) => {
                    if (res.code == 200) {
                        this.getPublishData();
                        this.idea = ''
                        this.checkVisible = false
                    }
                    // console.log('打印返回345的值', res)
                });
                return;
            }
            const publishForm = {
                id: this.getForm.id,
                token: this.token,
                source: 4,
                pass: this.pass === '1' ? '1' : '2'
            };
            publishNews(publishForm).then((res) => {
                if (res.code == 200) {
                    this.getPublishData();
                    this.idea = ''
                    this.checkVisible = false
                    // this.pass = '1'
                }
                // console.log('打印返回123的值', res)
            });
        }
    },
}
</script>
<style scoped>
/deep/.el-button--primary {
    background-color: #3082e2;
    border-color: #3082e2;
}

/deep/.el-button--primary:hover {
    background-color: #409EFF;
    border-color: #409EFF;
}

/deep/.el-input__suffix {
    right: 43px;
    transition: all .3s;
    border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
    background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
    min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
    border-bottom: 0;
}

/deep/.el-form-item__label {
    font-family: Adobe Heiti Std;
    color: #2D2D2D;
}

/deep/ .litop .el-input {
    width: 245px;
}

/deep/ .litop .el-input__inner {
    width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
    font-size: 16px;
}

/deep/.el-table .warning-row {
    background: rgba(48, 130, 226, 0.1);
}

/deep/ .el-link.el-link--primary {
    padding-right: 30px !important;
}

.pitable {
    /*height: 60vh;*/
}

.litop {
    margin: 20px 24px;
}

.litop span {
    color: #3086fb;
    margin-left: 10px;
    line-height: 40px;
}

.pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin: 30px 0;
}

.prbtn {
    display: flex;
    flex-direction: row-reverse;
}

.linkto {
    text-decoration: none;
    color: #3082E2;
}

.linkto>span:hover {
    color: #84b5ec;
}

.piconetn {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 9px 1px #EEEEEE;
}

.form_line {
    /* display: flex; */
}

.form_line .el-form-item {
    width: 90%;
    margin: 0 16px 24px 0;
}

.form_line .el-form-item .el-input,
.el-select {
    width: 80%;
}

.plus_add {
    margin: 20px 0 20px 0;
}

.agree {
    margin-left: 18px;
}

/deep/ .add_dialog {
    width: 700px;
}

/deep/ .add_dialog .el-form-item__label {
    text-align: left;
    width: 90px;
}

/deep/ .add_dialog .el-form-item__error {
    left: 89px;
}

/deep/ .preview_dialog {
    width: 500px;
}

.preview_dialog .img_top {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .preview_dialog .prev_img {
    vertical-align: middle;
    width: 210px;
    height: 210px;
} */

/deep/ .preview_dialog .el-link--inner {
    color: #0099fc;
}

/* .qrcode_img {
    vertical-align: middle;
    width: 50px;
    height: 50px;
} */

/deep/ .news_dialog {
    width: 70%;
}

/deep/ .news_dialog .el-dialog__header {
    text-align: center;
}

/deep/ .el-step__head.is-finish,
/deep/ .el-step__head.is-process {
    width: 320px;
}

/deep/ .big_code img {
    vertical-align: middle;
    width: 210px;
    height: 210px;
    margin-bottom: 11px;
}
</style>
<style scoped lang="scss">
.time_status {
    display: flex;
    justify-content: space-between;

    .show_text {
        width: 60%;
        display: flex;
        justify-content: space-between;

        .bottom_txt {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .create_date {
                padding-top: 6px;
            }
        }
    }

    .form_right {
        width: 50%;
        margin-top: 26px;
        margin-left: 13%;

        .text_word {
            margin: 16px 0 16px 0;
        }
    }
}

.double_add {
    display: flex;

    .suggest {
        margin-left: 18px;
    }
}

.subtext {
    color: #0099fc;
    cursor: pointer;
}
</style>