<template>
    <div>
      <div class="pititle">
        <div>
            <publicAnnounce></publicAnnounce>
        </div>
      </div>
    </div>
  </template>
  <script>
  //getProjectList  
  import { getBiaozhunList, } from "../../../../api/project";
  // import { organizeList,publishList } from "../../../../api/enterprise";
  import publicAnnounce from "../technicalReview/components/publicAnnounce.vue"
  
  export default {
    components: {publicAnnounce},
    filters: {
      formatDate: function (timestamp, fmt) {
        if (fmt == undefined || fmt == "" || fmt == null) {
          fmt = "yyyy-MM-dd ";
        }
        if (!timestamp) {
          return "";
        }
        let date = new Date(timestamp);
        // 第一步先替换年份 因为年份跟其他不一样是四位
        // let pat =
        if (/(y+)/.test(fmt)) {
          fmt = fmt.replace(RegExp.$1, date.getFullYear());
        }
        // 第二步匹配其他，然后替换
        let obj = {
          "M+": date.getMonth() + 1,
          "d+": date.getDate(),
          "h+": date.getHours(),
          "m+": date.getMinutes(),
          "s+": date.getSeconds(),
        };
        for (let key in obj) {
          let pat = `(${key})`;
          if (new RegExp(pat).test(fmt)) {
            let str = obj[key] + "";
            // RegExp.$1  MM dd hh mm ss贪婪匹配
            fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
          }
        }
        return fmt;
      },
    },
    data() {
      return {
        //选中的表格数据
        selectedProjects: [],
        form: {},
        page: 1,
        limit: 10,
        totalCount: 0,
        totalPage: 1,
        tableData: [],
        token: '',
        firstUnit: '',
        proName: '',
        zhidingType: '',
        biaozhunType: '',
        activeTitle: "public_announce",
        submenu: "standard_release",
        titleName: "",
        getObj: null,
        standardData: [],
      };
    },
    mounted() {
      this.getToken();
      // this.getList();
      //查询公示公告的标准文本 todo
    },
    methods: {
      getToken() {
        this.token = this.$store.state.token//获取cookie字符串
        console.log(this.token);
        // this.getbiaozhunList()
      },
      tabRowClassName({ rowIndex }) {
        let index = rowIndex + 1;
        if (index % 2 == 0) {
          return 'warning-row'
        }
      },
      contentChange(content){
        // console.log('接收文本', content)
        this.editForm.content = content
      },
      //切换菜单
      handleMenu() {
        if (this.activeTitle == 'standardText') {
          this.getList();
        }else if(this.activeTitle == 'public_announce'){
          //公告
  
        }
      },
      //获得表格数据
      getList() {
        let data = {
          limit: this.limit,
          page: this.page,
          token: this.token,
          proName: this.getObj == null ? '' : this.getObj.proName,
          firstUnit: this.getObj == null ? '' : this.getObj.firstUnit,
          zhidingType: this.getObj == null ? '' : this.getObj.zhidingType,
          biaozhunType: this.getObj == null ? '' : this.getObj.biaozhunType,
          status: '6,7'
        }
        console.log('条件查询', data)
        getBiaozhunList(data).then(rec => {
          // debugger
          console.log('lists', rec)
          this.tableData = rec.data
          this.tableData = rec.data
          this.limit = rec.limit
          this.page = rec.page
          this.totalCount = rec.totalCount
          this.totalPage = rec.totalPage
          console.log(rec)
        })
      },
      //搜索
      search(data) {
        console.log(data, '打印值')
        this.getObj = data
        this.page = 1;
        this.getList();
      },
      //清空
      qingkong() {
        this.firstUnit = '',
          this.proName = '',
          this.zhidingType = '',
          this.biaozhunType = '',
          this.getList();
      },
      // 分页
      handleSizeChange(page) {
        this.limit = page;
        this.getList();
        //console.log(`每页 ${page} 条`)
      },
      handleCurrentChange(page) {
        //console.log(`当前页: ${page}`)
        this.page = page;
        this.getList();
      },
      //表格多选
      selected_change(val) {
        this.selectedProjects = val;
      },
      gotoStep(row) {
        let url = '/technology/Draftreview?id=' + row.id;
        return url;
      },
      exportExcel() {  //导出表单
        let url = 'https://greenapi.cdmp.tech/api/standard/export?';
        let idarr = this.selectedProjects.map((item) => {
          return item.id
        });
        let ids = idarr.toString();
        url += 'token=' + this.$store.state.token;
        url += '&ids=' + ids;
        window.open(url, 'blank');
        console.log(url)
        // getExcel({ids:ids,token:this.token}).then( rec =>{
        //   console.log(rec)
        // })
      },
      //初审
      primaryCheck() {
        // debugger
        // this.$router.push({path:"Draftreview",query:{}})
      },
      addFrom() {
        this.editDialogVisible = true
        this.enterpriseData()
      },
    },
  };
  </script>
  <style scoped>
  /deep/.el-button--primary {
    background-color: #3082e2;
    border-color: #3082e2;
  }
  
  /deep/.el-button--primary:hover {
    background-color: #409EFF;
    border-color: #409EFF;
  }
  
  /deep/.el-input__suffix {
    right: 43px;
    transition: all .3s;
    border-left: 1px solid #c0c4cc;
  }
  
  /deep/.el-table__row .el-table__row--striped {
    background-color: rgba(48, 130, 226, 0.1);
  }
  
  /deep/ .el-table th.el-table__cell>.cell {
    min-width: 100px;
  }
  
  /deep/ .el-table th.el-table__cell.is-leaf,
  /deep/ .el-table td.el-table__cell {
    border-bottom: 0;
  }
  
  /deep/.el-form-item__label {
    font-family: Adobe Heiti Std;
    color: #2D2D2D;
  }
  
  /deep/ .litop .el-input {
    width: 245px;
  }
  
  /deep/ .litop .el-input__inner {
    width: 204px;
  }
  
  /deep/ .el-table th.el-table__cell>.cell {
    font-size: 16px;
  }
  
  /deep/.el-table .warning-row {
    background: rgba(48, 130, 226, 0.1);
  }
  
  /deep/ .el-link.el-link--primary {
    padding-right: 30px !important;
  }
  
  .pitable {
    /*height: 60vh;*/
  }
  
  .litop {
    margin: 20px 24px;
  }
  
  .litop span {
    color: #3086fb;
    margin-left: 10px;
    line-height: 40px;
  }
  
  .pageTotal {
    display: flex;
    flex-direction: row-reverse;
    margin: 30px 0;
  }
  
  .prbtn {
    display: flex;
    flex-direction: row-reverse;
  }
  
  .linkto {
    text-decoration: none;
    color: #3082E2;
  }
  
  .linkto>span:hover {
    color: #84b5ec;
  }
  
  /* 新改 */
  .zhidingsearch {
    background-color: #ffffff;
    padding: 1px 15px;
    margin: 20px 0 10px 0;
  }
  
  .piconetn {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 3px 9px 1px #EEEEEE;
  }
  
  .form_line {
    display: flex;
  }
  
  .form_line .el-form-item {
    width: 50%;
    margin: 0 16px 24px 0;
  }
  
  .form_line .el-form-item .el-input,
  .el-select {
    width: 80%;
  }
  
  .plus_add {
    margin: 20px 0 20px 0;
  }
  .agree{
    margin-left:18px;
  }
  </style>
    